<template>
	<div id="banner-form">
		<ValidationObserver ref="form">
			<form @submit.prevent="applyForm">
				<div class="banner-form__header mb-3">
					<h5 v-if="!isUpdate">{{ $t("BannerForm.CreateBanner") }}</h5>

					<template v-else>
						<h5>{{ getTitleUpdate(recentBanner) }}</h5>
					</template>
				</div>

				<CContainer>
					<CRow>
						<CCol md="6" class="py-3">
							<div class="banner-form__container">
								<!-- BANNER NAME -->
								<div class="form-group required">
									<label for="name" class="form-group__label col-form-label">{{
										$t("BannerForm.Name")
									}}</label>
									<div class="form-group__input">
										<ValidationProvider
											v-slot="{ classes, errors }"
											ref="banner-name"
											name="banner_name"
											rules="required"
										>
											<div :class="[classes]">
												<input
													id="name"
													v-model.trim="bannerForm.name"
													type="text"
													class="form-control"
													:placeholder="$t('BannerForm.PlaceHolderName')"
													:maxlength="nameLength"
												/>
												<div class="error-mess">{{ errors[0] }}</div>
											</div>
										</ValidationProvider>
									</div>
								</div>
								<!-- END BANNER NAME -->

								<!-- BANNER TYPE -->
								<div class="form-group required">
									<label for="type" class="form-group__label col-form-label">{{
										$t("BannerForm.Type")
									}}</label>
									<div class="form-group__input">
										<ValidationProvider
											ref="banner-type"
											v-slot="{ classes, errors }"
											name="banner_type"
											rules="required"
										>
											<div :class="[classes]">
												<multiselect
													id="type"
													v-model="selectedType"
													:options="bannerType"
													:custom-label="typeLabel"
													:searchable="false"
													:close-on-select="true"
													:show-labels="false"
													placeholder="Chon loại"
													@close="checkValidate('banner-type')"
												>
												</multiselect>

												<div class="error-mess">{{ errors[0] }}</div>
											</div>
										</ValidationProvider>
									</div>
								</div>
								<!-- END BANNER TYPE -->

								<!-- BANNER LINK -->
								<div v-if="selectedType != 'TUTORIAL'" class="form-group required">
									<label for="link" class="form-group__label col-form-label">{{
										$t("BannerForm.Link")
									}}</label>
									<div class="form-group__input">
										<ValidationProvider
											ref="banner-link"
											v-slot="{ classes, errors }"
											name="banner_link"
											rules="required"
										>
											<div :class="[classes]">
												<input
													id="link"
													v-model.trim="bannerForm.link"
													type="text"
													class="form-control"
													:placeholder="$t('BannerForm.PlaceHolderLink')"
												/>
												<div class="error-mess">{{ errors[0] }}</div>
											</div>
										</ValidationProvider>
									</div>
								</div>
								<!-- END BANNER LINK -->

								<!-- BANNER TIME -->
								<template v-if="selectedType != 'TUTORIAL'">
									<div class="form-group mb-2 required">
										<label for="time" class="form-group__label">{{ $t("BannerForm.Time") }}</label>
										<div class="form-group__input">
											<ValidationProvider
												ref="banner-time"
												v-slot="{ classes, errors }"
												name="banner_time"
												rules="required"
											>
												<div :class="[classes]">
													<date-picker
														id="time"
														v-model.trim="dateRange"
														type="date"
														value-type="format"
														range
														class="w-100"
														:confirm="false"
														:confirm-text="$t('Button.Find')"
														:editable="false"
														:placeholder="$t('DatePicker.SelectDateTime')"
														:show-time-panel="panelFinishedDate"
														@close="handleOpenChange('panelFinishedDate')"
													></date-picker>
													<div class="error-mess">{{ errors[0] }}</div>
												</div>
											</ValidationProvider>
										</div>
									</div>
								</template>
								<!-- END BANNER TIME -->

								<!-- BANNER STATUS -->
								<div class="form-group required">
									<label for="status" class="form-group__label col-form-label">{{
										$t("BannerForm.Status")
									}}</label>
									<div class="form-group__input">
										<ValidationProvider
											ref="banner-status"
											v-slot="{ classes, errors }"
											name="banner_status"
											rules="required"
										>
											<div :class="[classes]">
												<multiselect
													id="status"
													v-model="selectedStatus"
													:options="bannerStatus"
													:custom-label="statusLabel"
													:searchable="false"
													:close-on-select="true"
													:show-labels="false"
													placeholder="Chọn trạng thái"
													@close="checkValidate('banner-status')"
												>
												</multiselect>

												<div class="error-mess">{{ errors[0] }}</div>
											</div>
										</ValidationProvider>
									</div>
								</div>
								<!-- END BANNER STATUS -->
							</div>
						</CCol>

						<CCol lg="6" class="py-3">
							<template>
								<div
									id="my-strictly-unique-vue-upload-multiple-image"
									style="display: flex; justify-content: center"
								>
									<vue-upload-multiple-image
										ref="upload-form"
										:show-primary="false"
										:data-images="images"
										:max-image="maxImage"
										@upload-success="uploadImageSuccess"
										@before-remove="beforeRemove"
										@edit-image="editImage"
										@data-change="dataChange"
										@limit-exceeded="limitExceeded"
										@reset-success="resetSuccess"
									></vue-upload-multiple-image>
								</div>
							</template>
						</CCol>
					</CRow>

					<CRow>
						<CCol>
							<div v-if="isInvalidForm" class="text-center text-danger mb-2"
								>{{ $t("FormError.images") }}
							</div>
							<div class="banner-form__action text-center button-container">
								<CButton class="m-2" v-bind="{ variant: 'outline' }" color="info" type="submit"
									>{{ textSubmit }}
								</CButton>
								<CButton
									v-if="isUpdate"
									class="m-2"
									v-bind="{ variant: 'outline' }"
									color="warning"
									@click="toggleDeleteModal(true)"
									>{{ $t("Button.Delete") }}
								</CButton>
								<CButton
									v-bind="{ variant: 'outline' }"
									color="danger"
									class="m-2"
									@click="cancelForm"
								>
									{{ $t("Button.Exit") }}
								</CButton>
							</div>
						</CCol>
					</CRow>
				</CContainer>
			</form>
		</ValidationObserver>

		<!-- Show modal when change admin status -->
		<CustomModal
			v-if="isShowStatusModal"
			:is-show-submit="false"
			:cancel-color="'outline-custom'"
			:cancel-text="$t('Button.OK')"
			@cancel-modal="confirmStatus"
		>
			<template #content>
				{{ $t("ClinicForm.ConfirmStatus") }}
			</template>
		</CustomModal>

		<!-- Show modal when delete form -->
		<CustomModal
			v-if="isShowDeleteModal"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Close')"
			@submit-modal="deleteMessage"
			@cancel-modal="toggleDeleteModal(false)"
		>
			<template #content>
				<p>{{ $t("Button.AreYouSure") }}</p>
				<span>{{ $t("BannerForm.DeleteDescription") }}</span>
			</template>
		</CustomModal>

		<!-- Show modal when cancel if clinicForm was changed -->
		<CustomModal
			v-if="isShowDiscardModal"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Close')"
			@submit-modal="discardChanges"
			@cancel-modal="toggleDiscardModal(false)"
		>
			<template #content>
				<p>{{ $t("Button.DiscardChanges") }}</p>
				<span>{{ $t("Button.DiscardContent") }}</span>
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import { get, isEqual, orderBy } from "lodash-es"
import { GET_CLINIC } from "../../admins/store/action-types"

import { INPUT_LENGTH } from "@/shared/plugins/constants"
import { BANNER_TYPE, CLINIC_STATUS } from "../../../shared/plugins"
import VueUploadMultipleImage from "../../../shared/components/VueUploadMultipleImage"
import {
	CHANGE_STATUS_DISCARD_MODAL,
	CHANGE_STATUS_BANNER_MODAL,
	CHANGE_STATUS_DELETE_MODAL,
} from "../store/mutation-types"
import moment from "moment"

export default {
	name: "BannerForm",

	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
	},

	component: {
		VueUploadMultipleImage,
	},

	data() {
		return {
			bannerForm: {
				name: null,
				link: null,
				status: CLINIC_STATUS.ACTIVE,
				type: BANNER_TYPE.CAMPAIGN,
				dateRange: [],
				images: [],
			},
			originForm: {
				name: null,
				link: null,
				status: CLINIC_STATUS.ACTIVE,
				type: BANNER_TYPE.CAMPAIGN,
				dateRange: [],
				images: [],
			},
			codeLength: INPUT_LENGTH.CODE,
			nameLength: INPUT_LENGTH.CLINIC_NAME,
			dateRange: [],
			clientDateFormat: process.env.VUE_APP_SERVER_DATE_FORMAT,

			selectedType: BANNER_TYPE.CAMPAIGN,
			bannerType: [BANNER_TYPE.CAMPAIGN, BANNER_TYPE.BANNER, BANNER_TYPE.TUTORIAL],
			selectedStatus: CLINIC_STATUS.ACTIVE,
			bannerStatus: [CLINIC_STATUS.ACTIVE, CLINIC_STATUS.INACTIVE],
			images: [],
			isInvalidForm: false,
			fileList: [],
			maxImage: 1,
			panelFinishedDate: false,
		}
	},

	computed: {
		...mapState("banners", [
			"recentBanner",
			"isShowStatusModal",
			"isShowDiscardModal",
			"isShowDeleteModal",
		]),
		...mapState("authentication", ["currentUser"]),
		textSubmit: function () {
			return this.isUpdate ? this.$t("Button.SaveChanges") : this.$t("Button.CreateNew")
		},
	},

	watch: {
		selectedType: function (type) {
			if (type === BANNER_TYPE.TUTORIAL) {
				this.maxImage = 6
			} else {
				this.maxImage = 1
			}

			this.$refs["upload-form"]?.reset()
			this.images = []
			this.listImage = []
		},
		// eslint-disable-next-line no-unused-vars
		recentBanner: function (value) {
			this.setUpData()
		},
		dateRange: function (value) {
			if (value != null && value.length > 0 && value[0] == null) {
				this.dateRange = null
			}
		},
	},

	created() {
		this.setUpData()
	},

	mounted() {},

	beforeDestroy() {
		this.bannerForm = {}
		this.dateRange = []
	},

	methods: {
		get,

		...mapActions("banners", { GET_CLINIC }),
		...mapMutations("banners", {
			CHANGE_STATUS_DISCARD_MODAL,
			CHANGE_STATUS_DELETE_MODAL,
			CHANGE_STATUS_BANNER_MODAL,
		}),
		getTitleUpdate(data) {
			if (data) {
				return this.typeLabel(data.type) + " " + data.name
			}
		},
		typeLabel(value) {
			return {
				[BANNER_TYPE.TUTORIAL]: this.$t("BannerForm.Tutorial"),
				[BANNER_TYPE.CAMPAIGN]: this.$t("BannerForm.Campaign"),
				[BANNER_TYPE.BANNER]: this.$t("BannerForm.Banner"),
			}[value]
		},

		statusLabel(value) {
			return {
				[CLINIC_STATUS.ACTIVE]: this.$t("BannerForm.ACTIVE"),
				[CLINIC_STATUS.INACTIVE]: this.$t("BannerForm.INACTIVE"),
			}[value]
		},
		setUpData() {
			if (this.isUpdate && this.recentBanner) {
				this.bannerForm = { ...this.originForm }
				this.bannerForm.name = this.recentBanner?.name
				this.bannerForm.link = this.recentBanner?.link
				this.selectedType = this.recentBanner?.type
				this.selectedStatus = this.recentBanner?.status
				if (this.recentBanner != null && this.recentBanner.beginDate) {
					let tempDateRange = []
					tempDateRange.push(moment(this.recentBanner.beginDate, "DD/MM/YYYY").format("YYYY-MM-DD"))
					tempDateRange.push(moment(this.recentBanner.endDate, "DD/MM/YYYY").format("YYYY-MM-DD"))
					this.dateRange = tempDateRange
				}
				this.loadListImage(this.recentBanner)
			} else {
				this.bannerForm = {}
			}
		},

		async loadListImage(recentBanner) {
			let images = recentBanner.images
			let shorted = orderBy(images, ["order"], ["asc"])
			let response = await Promise.all(
				shorted.map(async item => {
					return await this.getImageUriFromUrl(item.imageUrl)
				}),
			)
			this.images = []

			for (const responseElement of response) {
				this.images.push({
					path: responseElement.path,
					name: responseElement.name,
					file: responseElement.file,
				})
			}
			this.fileList = this.images
		},

		async getImageUriFromUrl(url) {
			const data = await fetch(url)
			const blob = await data.blob()
			var filename = url.substring(url.lastIndexOf("/") + 1)

			const file = new File([blob], filename, { type: blob.type })
			return new Promise(resolve => {
				const reader = new FileReader()
				reader.readAsDataURL(blob)
				reader.onloadend = () => {
					const base64data = reader.result
					resolve({
						path: base64data,
						name: filename,
						file: file,
					})
				}
			})
		},

		applyForm: async function () {
			const valid = await this.$refs.form.validate()

			if (this.fileList.length === 0) {
				this.isInvalidForm = true
			} else {
				this.isInvalidForm = false
			}

			if (!valid || this.fileList.length === 0) {
				return
			}

			const params = {
				...this.bannerForm,
				status: this.selectedStatus,
				type: this.selectedType,
				id: this.recentBanner?.id,
			}

			if (this.dateRange && this.dateRange.length > 1) {
				params.beginDate = this.dateRange[0]
				params.endDate = this.dateRange[1]
			}

			const formData = new FormData()
			for (const key in params) {
				formData.append(key, params[key])
			}

			for (let i = 0; i < this.fileList.length; i++) {
				const index = "file" + (i + 1)
				if (this.fileList[i].file) {
					formData.append(index, this.fileList[i].file)
				}
			}

			this.$emit("apply-form", formData)
		},
		checkValidate(field) {
			if (this.$refs[field]) this.$refs[field].validate()
		},

		cancelForm() {
			let isEq
			if (this.isUpdate) {
				const dataForm = {}
				for (const key in this.recentBanner) {
					dataForm[key] = this.bannerForm[key]
				}
				isEq = isEqual(dataForm, this.recentBanner)
			} else isEq = isEqual(this.bannerForm, this.originForm)

			if (!isEq) this.toggleDiscardModal(true)
			else this.$emit("cancel-form")
		},
		// Up Load
		uploadImageSuccess(formData, index, fileList) {
			this.fileList = fileList
		},
		// eslint-disable-next-line no-unused-vars
		beforeRemove(index, done, oldFileList) {
			var r = confirm(this.$t("BannerForm.RemoveImage"))
			if (r == true) {
				done()
				if (index >= 0) {
					this.fileList.splice(index, 1)
				}
			}
		},
		editImage(formData, index, fileList) {
			this.fileList = fileList
		},
		// eslint-disable-next-line no-unused-vars
		dataChange(data) {},
		// eslint-disable-next-line no-unused-vars
		limitExceeded(amount) {},
		resetSuccess() {
			this.fileList = []
		},
		toggleDeleteModal(status = false) {
			this.CHANGE_STATUS_DELETE_MODAL(status)
		},
		confirmStatus() {
			this.CHANGE_STATUS_BANNER_MODAL(false)
		},
		toggleDiscardModal(status = false) {
			this.CHANGE_STATUS_DISCARD_MODAL(status)
		},
		discardChanges() {
			this.toggleDiscardModal(false)
			this.$emit("cancel-form")
		},
		deleteMessage() {
			this.$emit("delete-form", this.recentBanner)
			this.toggleDeleteModal(false)
		},
		handleOpenChange(panel) {
			this[panel] = false
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.banner-form {
	&__header {
		display: flex;
		flex-wrap: wrap;
		gap: 24px;
	}

	&__container {
		display: grid;
		grid-gap: 10px 20px;
		width: 100%;
		margin-bottom: 15px;
		border-radius: 12px;
	}
}

input[type="text"] {
	height: 35px !important;
}

.form-group {
	display: flex;
	flex-wrap: wrap;
	gap: 2%;
	margin-bottom: 0;

	&[stretch] {
		.form-group__input {
			flex: 0 0 100%;
			max-width: 100%;

			@media (min-width: $xs) {
				flex: 0 0 75%;
				max-width: 100%;
			}
		}
	}

	&__label {
		flex: 0 0 100%;
		max-width: 100%;

		@media (min-width: $xs) {
			flex: 0 0 18%;
			max-width: 18%;
		}
	}

	&__input {
		flex: 0 0 70%;
		max-width: 70%;

		@media (min-width: $xs) {
			flex: 0 0 70%;
			max-width: 70%;
		}

		@media (min-width: $s) {
			flex: 0 0 70%;
			max-width: 70%;
		}

		@media (min-width: $ms) {
			flex: 0 0 70%;
			max-width: 70%;
		}
	}
}

.form-group.required > label::after {
	content: "*";
	color: $color-red;
	margin-left: 2px;
}

.group-time {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	margin-bottom: 10px;

	&__text {
		align-self: center;
	}

	&__input {
		width: 75px;
	}

	&__add {
		text-decoration: underline;
		align-self: center;
	}
}

.group-time + .error-mess {
	position: relative;
	top: -8px;
}

/deep/ .multiselect {
	font-size: 14px;

	&__single {
		font-size: 14px;
	}

	&__tag {
		margin-bottom: 0;
	}
}

/deep/ .vue-star-rating {
	padding-top: 1px;
	align-self: start;

	.vue-star-rating-star {
		display: block;
	}
}

.grid {
	@include grid-between;
	grid-template-columns: 1fr;
	gap: 5px;

	.error-mess {
		grid-column: 1 / -1;
	}
}

.button-container {
	place-content: center;
	justify-content: center;
}

.small-text {
	font-size: 12.5px;
}
</style>
